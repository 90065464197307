export interface Data {
  id : number;
  category : string;
}

export const filterData  : Data[]= [
  {id:0, category:"총류"},
  {id:1, category:"철학"},
  {id:2, category:"종교"},
  {id:3, category:"사회과학"},
  {id:4, category:"자연과학"},
  {id:5, category:"기술과학"},
  {id:6, category:"예술"},
  {id:7, category:"언어"},
  {id:8, category:"문학"},
  {id:9, category:"역사"},
]

