const FAQ = [
  {
    id : 1,
    title : "도서 데이터를 선정한 이유는 뭔가요?",
    description : `과거 업무를 했던 곳이 공공도서관 홈페이지를 만드는 업체였습니다. 그러다보니 자연스럽게 책에 대한 이해도가 있다고 판단하여 도서 정보를 선택하였습니다.`
  },
  {
    id : 2,
    title : "데이터는 어디서 가져오나요?",
    description : `국립중앙도서관 산하 도서관 정보나루라는 곳에서 정보를 가져오고 있습니다. 도서관 정보나루는 전국 공공도서관에서 수집한 회원·장서·대출 데이터 등을 제공하여 사서, 연구(기획)자, 민간사업자 등이 다양한 분석, 융합 콘텐츠 생산 및 새로운 서비스를 개발할 수 있도록 지원합니다.`
  },
  {
    id : 3,
    title : "현재 어떤 도서 데이터 정보를 가져오고 있나요?",
    description : "현재는 서울시 구립도서관의 일정 기간 동안 대출 베스트 정보를 가져오고 있습니다."
  },
  {
    id : 4,
    title : "가져오는 데이터 제한이 있나요?",
    description : "네, 데이터 개수 제한이 있습니다. 테스트 용도론 일일 500회로 한정되어있습니다. 만약 데이터를 더이상 가져오지 못할 경우 데이터를 한도를 초과때문일 수도 있습니다."
  },
  {
    id : 5,
    title : "그림책 이미지 정보는 어디서 가져오나요?",
    description : "그림책의 이미지는 2가지로 분류됩니다. 1. 국립중앙도서관, 2. 카카오 API 2가지 입니다. 국립중앙도서관 도서 이미지 정보는 상세페이지에서 사용되고 카카오 이미지 정보는 검색목록에서 사용되고 있습니다. 따라서 목록 이미지와 상세 이미지에 차이가 발생할 수 있습니다."
  },

]

export default FAQ